<template>
  <div class="horsesType">
    <div class="container">
      <div class="typeLeft">
        <div class="typeDesc">
          <p>4 types of Genesis Horses</p>
          <p>6 major Genetic Attributes</p>
        </div>
        <div class="typesData">
          <div>
            <div
              class="tipsWrap gripWrap"
              @mouseenter="mouseItem = 'grip'"
              @mouseleave="mouseItem = ''"
            ></div>
            <div
              class="tips gripTip"
              :style="mouseItem === 'grip' && 'display: block'"
            >
              Adaptability to the track
            </div>
            <div
              class="tipsWrap intellWrap"
              @mouseenter="mouseItem = 'intell'"
              @mouseleave="mouseItem = ''"
            ></div>
            <div
              class="tips intellTip"
              :style="mouseItem === 'intell' && 'display: block'"
            >
              Intelligent control or the degree of cooperation between the
              jockey and the horse
            </div>
            <div
              class="tipsWrap horseHeadWrap"
              @mouseenter="mouseItem = 'horseHead'"
              @mouseleave="mouseItem = ''"
            ></div>
            <div
              class="tips horseHeadTip"
              :style="mouseItem === 'horseHead' && 'display: block'"
            >
              Ability to adapt to corners
            </div>
            <div
              class="tipsWrap speedWrap"
              @mouseenter="mouseItem = 'speed'"
              @mouseleave="mouseItem = ''"
            ></div>
            <div
              class="tips speedTip"
              :style="mouseItem === 'speed' && 'display: block'"
            >
              Maximum speed in a straight line
            </div>
            <div
              class="tipsWrap enduranceWrap"
              @mouseenter="mouseItem = 'endurance'"
              @mouseleave="mouseItem = ''"
            ></div>
            <div
              class="tips enduranceTip"
              :style="mouseItem === 'endurance' && 'display: block'"
            >
              Ability for racing for a long time
            </div>
            <div
              class="tipsWrap accelerationWrap"
              @mouseenter="mouseItem = 'acceleration'"
              @mouseleave="mouseItem = ''"
            ></div>
            <div
              class="tips accelerationTip"
              :style="mouseItem === 'acceleration' && 'display: block'"
            >
              Ability for picking up speed
            </div>

            <img
              class="dataImg"
              v-lazy="require(`@/assets/images/${gifName}-Chart.png`)"
              alt=""
            />
          </div>
          <img
            class="typeData_gif"
            v-lazy="require(`@/assets/images/${gifName}.gif`)"
            alt=""
          />
          <div class="typeData_horseItem">
            <div
              :class="
                gifName === 'Darley' ? 'horseItems Watt_active' : 'horseItems'
              "
              @click="changeGif('Darley')"
            >
              <p>Darley Arabian</p>
            </div>
            <div
              :class="
                gifName === 'Godolphin' ? 'horseItems Bel_active' : 'horseItems'
              "
              @click="changeGif('Godolphin')"
            >
              <p>Godolphin Arabian</p>
            </div>
            <div
              :class="
                gifName === 'Byerly'
                  ? 'horseItems Einstein_active'
                  : 'horseItems'
              "
              @click="changeGif('Byerly')"
            >
              <p>Byerly Turk</p>
            </div>
            <div
              :class="
                gifName === 'Satoshi'
                  ? 'horseItems Satoshi_active'
                  : 'horseItems'
              "
              @click="changeGif('Satoshi')"
            >
              <p>Satoshi</p>
              <p>Nakamoto</p>
            </div>
          </div>
        </div>
        <div>
          <p class="typesLeft_text">
            The presentation of specific gene is subject to actual game.
          </p>
          <div class="typesLeft_btn">
            <p><span>ALGEBRA</span> <span>M0S0</span></p>
            <p><span>Tamebility</span> <span>1000</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HouseType",
  data() {
    return {
      gifName: "Darley",
      mouseItem: "",
    };
  },

  methods: {
    changeGif(name) {
      this.gifName = name;
    },
  },
};
</script>

<style scoped lang='scss'>
.horsesType {
  overflow: hidden;
  .container {
    width: 1200px;
    height: 100%;
    padding: 0;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  width: 100%;
  // height: 760px;
  padding: 52px 0 84px 0;
  background: linear-gradient(90deg, #640c96 0%, #080018 35%, #080018 100%);
  .typeLeft {
    display: flex;
    flex-direction: column;

    .typeDesc {
      font-family: "Rubik-BoldItalic";
      font-size: 42px;
      font-weight: normal;
      color: #fff;
    }

    .typesData {
      width: 1200px;
      margin: 73px 0 35px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      .tipsWrap {
        height: 40px;
        position: absolute;
        cursor: pointer;
        z-index: 9999;
      }
      .tips {
        display: none;
        position: absolute;
        z-index: 9999;
        background-color: #404041;
        color: #fff;
        padding: 10px 15px;
        border-radius: 10px;
        max-width: 270px;
        font-size: 15px;
      }

      .gripTip,
      .gripWrap {
        top: -10px;
        left: 20px;
      }
      .gripWrap {
        width: 60px;
        left: 232px;
      }
      .intellTip,
      .intellWrap {
        top: 85px;
        left: 410px;
      }
      .intellWrap {
        width: 100px;
        top: 42px;
        left: 360px;
      }
      .horseHeadTip,
      .horseHeadWrap {
        top: 290px;
        left: 370px;
      }
      .horseHeadWrap {
        width: 120px;
        top: 240px;
      }
      .speedTip,
      .speedWrap {
        top: 318px;
        left: 305px;
      }
      .speedWrap {
        width: 80px;
        top: 310px;
        left: 220px;
      }
      .enduranceTip,
      .enduranceWrap {
        top: 290px;
        left: 0px;
      }
      .enduranceWrap {
        width: 120px;
        top: 240px;
        left: 20px;
      }
      .accelerationTip,
      .accelerationWrap {
        top: 85px;
        left: 0px;
      }
      .accelerationWrap {
        width: 150px;
        top: 43px;
        left: 20px;
      }
      .dataImg {
        height: 349px;
        width: 511px;
      }
      .typeData_gif {
        position: absolute;
        left: 50%;
        top: -260px;
        margin-left: -300px;
        height: 760px;
        width: 760px;
      }
      .typeData_horseItem {
        position: absolute;
        right: 0;
        .horseItems {
          height: 90px;
          width: 287px;
          background: url("../assets/images/rankIcon1.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: center;
          font-size: 20px;
          font-family: Rubik-MediumItalic;
          padding-top: 25px;
          margin-top: 18px;
          box-sizing: border-box;
          cursor: pointer;
          p {
            width: 100px;
            text-align: left;
            margin-left: 110px;
          }
        }
        .horseItems:nth-child(2) {
          background: url("../assets/images/rankIcon2.png") no-repeat;
        }
        .horseItems:nth-child(3) {
          background: url("../assets/images/rankIcon3.png") no-repeat;
        }
        .horseItems:nth-child(4) {
          background: url("../assets/images/rankIcon4.png") no-repeat;
        }

        .Watt_active,
        .horseItems:nth-child(1):hover {
          background: url("../assets/images/rankIcon1_hover.png") no-repeat !important;
          background-size: 100% 100% !important;
        }

        .Bel_active,
        .horseItems:nth-child(2):hover {
          background: url("../assets/images/rankIcon2_hover.png") no-repeat !important;
          background-size: 100% 100% !important;
        }
        .Einstein_active,
        .horseItems:nth-child(3):hover {
          background: url("../assets/images/rankIcon3_hover.png") no-repeat !important;
          background-size: 100% 100% !important;
        }
        .Satoshi_active,
        .horseItems:nth-child(4):hover {
          background: url("../assets/images/rankIcon4_hover.png") no-repeat !important;
          background-size: 100% 100% !important;
        }
      }
    }

    .typesLeft_text {
      font-size: 16px;
      color: #efefef;
      margin-bottom: 35px;
    }

    .typesLeft_btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 520px;
      p {
        width: 202px;
        height: 42px;
        color: #efefef;
        font-size: 16px;
        font-family: Rubik-MediumItalic;
        border: 2px solid #c822ff;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        box-sizing: border-box;
        span {
          display: inline-flex;
          width: 50%;
          height: 100%;
          align-items: center;
          justify-content: center;
          // text-align: center;
        }

        span:last-child {
          width: 100px;
          background-color: #c822ff;
        }
      }

      p:last-child {
        width: 305px;
        span:first-child {
          width: 205px;
        }
      }
    }
  }
}
</style>