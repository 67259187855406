<template>
  <div class="start_wrap">
    <div class="startBg">
      <div class="startText">
        <div class="joins">
          PRE-REGISTRATION
          <br />TO JOIN THE GENESIS NFT PRE-SALE
        </div>
        <div class="chance">
          Chance to purchase limited GENESIS NFT at a lower price
        </div>
        <div class="startBtn" @click="refPosition('videoIntroduce')">
          START NOW
        </div>
      </div>
    </div>
    <div class="container">
      <div class="video_wrap" id="videoIntroduce">
        <div class="videoTitle">
          <div>
            METARACE® brings horse racing into a futuristic virtual reality.
          </div>
          <div>Players compete with each other to win and earn coins,</div>
          <div>
            upgrade the mechanics of their horse, or sit on the sidelines and
            place a wager!
          </div>
        </div>
        <div class="videoBox">
          <video
            src="https://www.youtube.com/embed/B2LeEuhIOEg"
            poster="@/assets/images/videoBg.jpeg"
            ref="videos"
            controls
          />
        </div>
      </div>
      <div class="registry_wrap">
        <div class="registryTitle">JOIN THE PRE-REGISTRATION</div>
        <ul class="registryCon">
          <li>
            <div class="step">STEP 1</div>
            <img v-lazy="require('@/assets/images/taskIcon6.png')" alt="" />
            <div class="subTips">
              Subscribe. Stay up to date with our latest news and releases!
            </div>
            <input
              type="text"
              class="emailSub"
              placeholder="Email"
              v-model="emailVal"
            />
            <div class="regBtn" @click="subscribe">SUBSCRIBE</div>
          </li>
          <li>
            <div class="step">STEP 2</div>
            <img v-lazy="require('@/assets/images/taskIcon1.png')" alt="" />
            <div class="joinDis">
              Join Discord Complete the Task,Everyone have chance
            </div>
            <div class="regBtn">
              <a href="https://discord.gg/F9QZEpHSBE" target="_blank"
                >GO TO DISCORD</a
              >
            </div>
          </li>
        </ul>
      </div>
      <!-- task -->
      <div class="task_wrap">
        <div class="taskTitle">
          <div>FINISH THE TASK TO JOIN</div>
          <div>THE GENESIS NFT PRE-SALE</div>
        </div>
        <div class="taskCon">
          <div class="taskItem" v-for="item in taskArr" :key="item.id">
            <img :src="item.iconUrl" alt="" />
            <div class="taskTit">{{ item.taskTit }}</div>
            <div class="taskDec">{{ item.taskDec }}</div>
            <div v-if="item.taskDec1" class="taskDec1">{{ item.taskDec1 }}</div>
          </div>
        </div>
        <div class="taskFoot">
          <div>Whitelist #1 Deadline : (TBC)</div>
          <div>
            Selection Criteria: First 1000 users to successfully fulfill these
            requirements.
          </div>
        </div>
      </div>
      <div class="myStery_wrap">
        <img
          v-lazy="require('@/assets/images/myStery.png')"
          alt=""
          class="myStery"
        />
        <div class="gensis_wrap">
          <div class="sale">GENESIS NFT PRE-SALE</div>
          <div class="gensis_box">
            <div>METARACE NFT</div>
            <div>MYSTERY BOX</div>
          </div>
          <div class="getHorse">Randomly get a Genesis Horse NFT</div>
          <div class="seeBtn" @click="refPosition('HouseTypes')">SEE MORE</div>
          <ul>
            <li>Pre-sale Price: OOOO</li>
            <li>Public Sale Price: OOOO</li>
          </ul>
        </div>
      </div>
    </div>
    <HouseType id="HouseTypes" />
    <div class="term">
      <div class="termTitle">TERM & CONDITION</div>
      <ul class="container">
        <li>
          1. The top (number) users who successfully meet these requirements
          will be eligible for the MetaRace pre-sale whitelist.
        </li>
        <li>
          2. The top (number) platinum jockeys, who have the most invites in
          Discord, will be eligible for the MataRace pre-sale whitelist.
        </li>
        <li>
          3. 200 users who meet the above requirements (Discord Task) , will be
          randomly selected and rewarded 5 USDT.
        </li>
        <li>4. 1 whitelist registration per user.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import HouseType from "@/components/HorsesType";
export default {
  components: {
    HouseType,
  },
  data() {
    return {
      emailVal: "",
      taskArr: [
        {
          id: 1,
          iconUrl: require("../../assets/images/taskIcon1.png"),
          taskTit: "Join Discord",
          taskDec: "Be active, don't AFK",
        },
        {
          id: 2,
          iconUrl: require("../../assets/images/taskIcon2.png"),
          taskTit: "Chat with Community",
          taskDec: "You need to have at least a title on the server.",
          taskDec1:
            "You can easily level up by being active on the server staying online and chatting in the channels.",
        },
        {
          id: 3,
          iconUrl: require("../../assets/images/taskIcon3.png"),
          taskTit: "Sharing is Caring",
          taskDec: "You need to have a minimum of 5 invites.",
          taskDec1:
            "Remember only to give your friends your personalised link #how-to-invite",
        },
        {
          id: 4,
          iconUrl: require("../../assets/images/taskIcon4.png"),
          taskTit: "Follow Us",
          taskDec:
            "Like, Comment, Retweet the MetaRace Twitter pinned post and send the screenshots to # Twitter-Task.",
        },
        {
          id: 5,
          iconUrl: require("../../assets/images/taskIcon5.png"),
          taskTit: "Platinum Jockey - Qualified Gold Jockey - Qualified",
          taskDec: "TO JOIN THE GENESIS NFT PRE-SALE WHITELIST",
        },
      ],
    };
  },
  methods: {
    refPosition(name) {
      this.$router.push({ path: `/start#${name}` });
    },
    async subscribe() {
      if (!this.emailVal) return;
      const res = await this.$axios.post(`/smtp/sendEmil`, {
        domain: "metarace",
        to: "info.metarace@gmail.com",
        title: "SUBSCRIBE",
        content: this.emailVal,
      });
      let { status } = res;
      if (status === 200) {
        this.$message({
          // data.data
          message: "Sent successfully",
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
  height: 100%;
  padding: 0;
  margin: 0 auto;
}

.start_wrap,
.myStery_wrap,
.term,
.task_wrap,
.registry_wrap,
.video_wrap,
.startBg {
  width: 100%;
}
.start_wrap {
  .startBg {
    height: 676px;
    background: url("../../assets/images/startBg.jpg") no-repeat;
    background-size: 100% 100%;

    .startText {
      display: flex;
      flex-direction: column;
      color: #fff;
      align-items: center;
      position: relative;
      top: 53%;
      .joins {
        font-size: 51px;
        font-family: "Rubik-BoldItalic";
        color: #ff00ea;
        text-align: center;
      }
      .startBtn {
        cursor: pointer;
        width: 220px;
        height: 54px;
        background: #ff00ea;
        border-radius: 20px;
        text-align: center;
        line-height: 54px;
        font-size: 27px;
        font-family: "Rubik-BoldItalic";
      }
      .chance {
        font-size: 25px;
        font-family: "Rubik-MediumItalic";
        margin: 25px 0;
      }
    }
  }
  .video_wrap {
    margin-top: 58px;
    display: flex;
    flex-direction: column;
    .videoTitle {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 15px;
      font-family: "Rubik-MediumItalic";
      line-height: 24px;
      div:first-child {
        font-size: 25px;
        font-family: "Rubik-BoldItalic";
        margin-bottom: 18px;
      }
    }
    .videoBox {
      width: 100%;
      height: 650px;
      margin-top: 20px;

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  .registry_wrap {
    display: flex;
    flex-direction: column;
    margin-top: 83px;
    margin-bottom: 89px;
    align-items: center;
    .registryTitle {
      font-size: 40px;
      font-family: "Rubik-BoldItalic";
      color: #ff00ea;
      margin-bottom: 51px;
    }
    .registryCon {
      width: 100%;
      display: flex;
      justify-content: space-around;
      li {
        width: 510px;
        height: 475px;
        border: 3px solid #ff00ea;
        border-radius: 15px;
        padding: 36px 38px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        color: #fff;
        .step {
          font-size: 20px;
          font-family: Rubik-MediumItalic;
        }
        img {
          width: 84px;
          height: 84px;
          margin-top: 55px;
          margin-bottom: 57px;
        }
        .emailSub {
          width: 100%;
          height: 59px;
          background: #ffffff;
          border-radius: 15px;
          text-align: center;
          border: none;
          margin-bottom: 33px;
          margin-top: 13px;
          font-size: 18px;
          font-family: "Rubik-BoldItalic";
        }
        .emailSub:focus {
          outline: none;
          border: none;
        }
        .subTips {
          font-size: 13px;
          font-family: Rubik-MediumItalic;
        }
        .regBtn {
          width: 149px;
          height: 37px;
          line-height: 37px;
          font-family: "Rubik-BoldItalic";
          text-align: center;
          background: #ff00ea;
          border-radius: 10px;
          cursor: pointer;
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
      li {
        .regBtn {
          width: 198px;
        }
        .joinDis {
          text-align: center;
          font-size: 25px;
          font-family: Rubik-MediumItalic;
          margin-bottom: 55px;
        }
      }
    }
  }
  .task_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    margin-bottom: 86px;
    .taskTitle {
      font-size: 40px;
      text-align: center;
      font-family: "Rubik-BoldItalic";
    }
    .taskFoot {
      font-size: 20px;
      text-align: center;
      font-family: "Rubik-MediumItalic";
    }
    .taskCon {
      width: 100%;
      height: 450px;
      margin-top: 58px;
      margin-bottom: 45px;
      display: flex;
      justify-content: space-between;
      .taskItem {
        width: 229px;
        background: #111111;
        border-radius: 10px;
        height: 100%;
        padding: 28px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 137px;
          height: 137px;
          margin-bottom: 38px;
        }
        div {
          text-align: center;
        }
        .taskTit {
          height: 52px;
          font-size: 25px;
          font-family: "Rubik-MediumItalic";
          font-weight: 500;
          color: #ff00ea;
          margin-bottom: 13px;
        }
        .taskDec,
        .taskDec1 {
          font-size: 13px;
          font-family: "Rubik-MediumItalic";
          line-height: 20px;
        }
        .taskDec1 {
          margin-top: 22px;
        }
      }
      .taskItem:last-child {
        img {
          width: 137px;
          height: 152px;
          margin-bottom: 30px;
        }
        .taskTit {
          color: #fff;
          font-size: 13px;
        }
        .taskDec {
          font-family: Rubik-MediumItalic;
          font-size: 25px;
          line-height: 30px;
          color: #ff00ea;
        }
      }
    }
  }
  .myStery_wrap {
    display: flex;
    position: relative;

    .myStery {
      width: 766px;
      height: 724px;
    }
    .gensis_wrap {
      position: absolute;
      right: -3%;
      top: 90px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      .sale {
        font-size: 40px;
        font-family: "Rubik-BoldItalic";
        font-weight: normal;
        color: #ff00ea;
      }
      .gensis_box {
        font-family: "Rubik-BoldItalic";
        font-size: 79px;
        text-align: center;
        margin-top: 37px;
        margin-bottom: 40px;
      }
      .getHorse {
        font-size: 20px;
        font-family: "Rubik-Italic";
        font-weight: normal;
      }
      .seeBtn {
        width: 191px;
        height: 54px;
        background: #ff00ea;
        border-radius: 20px;
        margin-top: 98px;
        margin-bottom: 65px;
        font-size: 27px;
        font-family: "Rubik-BoldItalic";
        text-align: center;
        line-height: 54px;
        cursor: pointer;
      }
      ul {
        width: 75%;
        display: flex;
        justify-content: space-between;
        li {
          font-size: 20px;
        }
      }
    }
  }
}
.term {
  height: 501px;
  display: flex;
  flex-direction: column;
  .termTitle {
    font-size: 40px;
    text-align: center;
    font-family: "Rubik-BoldItalic";
    margin-top: 87px;
    margin-bottom: 62px;
  }
  ul {
    li {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 44px;
      font-family: "Rubik-Italic";
    }
  }
}
</style>
